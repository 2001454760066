import type { PluralizationRule } from "@intlify/core-base";

function customRule(
  choice: number,
  choicesLength: number,
  orgRule?: PluralizationRule,
) {
  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;
  if (!teen && endsWithOne) {
    return 0;
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 1;
  }

  return choicesLength < 3 ? 1 : 2;
}

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "ru",
  pluralRules: {
    ru: customRule,
  },
}));
